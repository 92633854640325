import React from 'react'

import { EmailLink } from '@/components/common/EmailLink'
import { Acknowledgement } from '@/components/Event/Script/components/Acknowledgement'
import { ActionItem } from '@/components/Event/Script/components/ActionItem'
import { ImportantNote } from '@/components/Event/Script/components/ImportantNote'
import { Note } from '@/components/Event/Script/components/Note'
import { OptionalPart } from '@/components/Event/Script/components/OptionalPart'
import { Verbatim } from '@/components/Event/Script/components/Verbatim'
import { Helpers } from '@/components/ScriptScreen'
import { Event, Reporter } from '@/types'

export const EuoScript: React.FC<{
  event: Event
  reporter: Reporter
  helpers: Helpers
}> = ({ event, reporter, helpers }) => {
  return (
    <div>
      <h1>EUO Script</h1>
      <ol className="list-decimal font-bold text-lg">
        <li>
          <div className="font-normal text-base">
            <h4>GREET PARTICIPANTS</h4>
            <ol className="list-decimal font-normal">
              <li>
                <span>Breakout Room Notice</span>
                <Verbatim>
                  Hi <strong>(Name of attendee)</strong>, I’m {reporter.name}{' '}
                  and I’m the Parrot digital reporter for today. Just to let you
                  know, everything in this main room is being recorded today.
                  Anything prior to the official on the record will{' '}
                  <u>not be saved</u>, but while we’re waiting for everyone
                  else, I can also place you in a breakout room as conversations
                  are not recorded there, or feel free to mute your microphone
                  while we wait.
                </Verbatim>
                <ImportantNote>
                  Unless a private breakout room is requested, put all
                  participants in the same breakout room.
                </ImportantNote>
              </li>
              <li>
                <span>After breakout room has been assigned and opened</span>
                <Verbatim>
                  There should be a button asking you to join a room now. I’ll
                  be here if you need anything, you can leave the breakout room
                  any time and you’ll be placed back here.
                </Verbatim>
              </li>
            </ol>
            {!['GA', 'IL'].includes(event.case.state) && (
              <OptionalPart title="CNA | Certificate of Non Appearance">
                <Verbatim>
                  Counsel, how long would you like us to wait for{' '}
                  <strong>{helpers.witness}</strong> to appear? And at that
                  time, would you like me to issue a CNA?
                </Verbatim>
                <Verbatim>
                  Today is <strong>{helpers.day_of_week}</strong>. It is{' '}
                  <strong>{helpers.date}</strong> the time is{' '}
                  <strong>{helpers.time}</strong>. We are present to record the
                  Examination Under Oath of <strong>{helpers.witness}</strong>{' '}
                  which was scheduled for <strong>{helpers.start_time}</strong>.
                  {'\n'}
                  {'\n'}
                  <strong>{helpers.witness}</strong> has failed to appear and
                  accordingly at this time I will be issuing a certificate of
                  non-appearance.
                </Verbatim>
                <ActionItem>
                  Note the exact time the CNA was called on the event form under{' '}
                  <i>How did the event end?</i> In the{' '}
                  <i>Time of declaration of CNA</i> section.
                </ActionItem>
                <ActionItem>
                  Confirm attendees list is complete and accurate on the
                  reporter form located on the Parrot platform.
                </ActionItem>
                <ActionItem>
                  Be ready to review and sign CNA asap, as it will be sent to
                  you quickly via email.
                </ActionItem>
              </OptionalPart>
            )}
          </div>
        </li>
        <li>
          <div className="font-normal text-base">
            <h4>START EUO</h4>
            <ActionItem>
              Spotlight the witness before going on the record.
            </ActionItem>
            <ol className="list-decimal font-normal">
              <li>
                <span>Intro</span>
                <Verbatim>
                  Good morning/afternoon, we are now on the record. Today is{' '}
                  <strong>{helpers.day_of_week}</strong>. It is{' '}
                  <strong>{helpers.date}</strong>, the time is{' '}
                  <strong>{helpers.time}</strong>. We are present to record the
                  Examination Under Oath of {helpers.witness}. My name is{' '}
                  {reporter.name}, I will be the Parrot Digital Reporter for
                  today’s proceedings.
                </Verbatim>
              </li>
              <li>
                <span>Appearances</span>
                <Verbatim>
                  Would counsel please identify themselves for the record and
                  spell their last name?
                </Verbatim>
                <Note>pause to allow attorney(s) to enter appearance</Note>
                <ImportantNote>
                  Often the witness/claimant is not represented by counsel, but
                  if they are, make sure their attorney also introduces
                  themselves and spells their last name for the record.
                </ImportantNote>
              </li>
              <li>
                <span>Stipulation</span>
                <Verbatim>
                  For the record, does counsel stipulate to this examination
                  under oath being conducted remotely?
                </Verbatim>
                <Note>pause to allow affirmative response</Note>
                <OptionalPart title="Interpreter Present">
                  <Verbatim>
                    Would the interpreter please identify themselves and spell
                    their last name for the record?
                  </Verbatim>
                  <Note>pause to allow interpreter to respond</Note>
                  <Verbatim>
                    Raise your right hand, (pause). Do you solemnly swear that
                    you will interpret the following questions from English into
                    (language), and the answers from (language) into English to
                    the best of your ability?
                  </Verbatim>
                  <Note>pause to allow interpreter to respond</Note>
                  <ActionItem>
                    Periodically pause during witness swear in to accommodate
                    interpreter translations.
                  </ActionItem>
                </OptionalPart>
                <OptionalPart title="Guardian Present | Minor Witness">
                  <Verbatim>
                    Would the guardian for the witness identify yourself,
                    confirm the nature of your relationship with the minor, and
                    spell your last name for the record?
                  </Verbatim>
                  <Note>pause to allow guardian to respond</Note>
                  <Verbatim>
                    Mr/Ms. <strong>{helpers.witness}</strong> can you please
                    hold a valid government photo identification up to the
                    screen for verification?{'\n'}
                    {'\n'}
                    Guardian has presented a ____ state identification. ID #
                    ________.
                  </Verbatim>
                  <ImportantNote>
                    If the guardian does not have valid, ID counsel may
                    stipulate to their identity on the record. See “No ID”
                    section below.
                  </ImportantNote>
                  <Verbatim>
                    Do you consent to this examination under oath being
                    conducted for <strong>{helpers.witness}</strong>?
                  </Verbatim>
                  <Note>pause to allow guardian to respond affirmatively</Note>
                </OptionalPart>
              </li>
              <li>
                <span>Witness ID</span>
                <Verbatim>
                  Would the witness please identify themselves and spell their
                  last name for the record?{'\n'}
                  {'\n'}
                  Mr./Ms. <strong>{helpers.witness}</strong>, can you please
                  hold a valid government photo identification up to the screen
                  to verify your identity?{'\n'}
                  {'\n'}
                  Witness has presented a ____________________ for verification.
                  ID # is ______________.
                </Verbatim>
                <OptionalPart title="No ID">
                  <Verbatim>
                    For the record, the witness has <u>not</u> presented a valid
                    ID for verification. Accordingly, do both sides stipulate
                    that this is the witness noticed in this examination under
                    oath.
                  </Verbatim>
                  <Note>
                    pause to allow both sides to respond affirmatively
                  </Note>
                </OptionalPart>
                <OptionalPart title="Expired ID">
                  <Verbatim>
                    For the record, the witness has presented an expired ID for
                    verification. Accordingly, do both sides stipulate to my
                    administering the oath to this witness.
                  </Verbatim>
                  <Note>
                    pause to allow both sides to respond affirmatively
                  </Note>
                </OptionalPart>
              </li>
              <li>
                <span>Witness Oath</span>
                <Verbatim>
                  Thank you. Mr./Ms. <strong>{helpers.witness}</strong>, Please
                  raise your right hand. Do you swear or affirm to tell the
                  truth, the whole truth and nothing but the truth during this
                  Examination Under Oath?
                </Verbatim>
                <Note>pause to allow witness to answer affirmatively</Note>
                <Verbatim>
                  Whenever you are ready, you may proceed with questioning
                </Verbatim>
                <ActionItem>
                  Mute your microphone and remain on camera while on the record.
                </ActionItem>
                <ActionItem>
                  Log minute timestamps of on/off record, exhibits, special
                  occurrences using the online reporter form.
                </ActionItem>
                <ActionItem>
                  Monitor audio integrity and request repetition or
                  clarification, as necessary.
                </ActionItem>
                <Acknowledgement>
                  Thank you for remaining professional and attentive throughout
                  the entire proceeding!
                </Acknowledgement>
              </li>
              <li>
                <span>Breaks</span>
                <Verbatim>
                  We are now OFF the record and the time is{' '}
                  <strong>{helpers.time}</strong>.{'\n'}
                  We are back ON the record and the time is{' '}
                  <strong>{helpers.time}</strong>.
                </Verbatim>
                <ImportantNote>
                  Breaks must be unanimous, if there is an objection from either
                  side, objection must be resolved before reading off the
                  record.
                </ImportantNote>
              </li>
              <li>
                <span>Conclusion</span>
                <ActionItem>
                  Confirm all counsel have completed questioning.
                </ActionItem>
                <ActionItem>
                  Confirm exhibits/spellings, if necessary.
                </ActionItem>
                <Verbatim>
                  Just as a reminder, certified transcript requests or questions
                  can be sent to{' '}
                  <EmailLink size="sm" email="support@parrothq.com" />.
                </Verbatim>
                <ImportantNote>
                  Parrot offers a 7-10 day (standard) turnaround; 3 day, and 24
                  hour turnaround time for an additional cost. Cost inquiries
                  and post-event transcript orders to be sent to{' '}
                  <EmailLink size="sm" email="support@parrothq.com" />.
                </ImportantNote>
                <Verbatim>
                  If there is nothing further, please allow me to take us off
                  the record. At this time, the Examination Under Oath is coming
                  to an end. It is <strong>{helpers.time}</strong> and we are
                  now off the record.
                </Verbatim>
                <ImportantNote>
                  Exhibits not already provided should be sent to{' '}
                  <EmailLink size="sm" email="exhibits@parrothq.com" />.
                </ImportantNote>
                <ImportantNote>
                  Transcript orders, special requests, cost breakdown inquiries,
                  etc. should be sent to{' '}
                  <EmailLink size="sm" email="support@parrothq.com" />.
                </ImportantNote>
              </li>
            </ol>
          </div>
        </li>
        <li>
          <div className="font-normal text-base">
            <h4>AFTER EUO</h4>
            <ActionItem>
              Confirm counsel has no outstanding questions or concerns, before
              leaving the meeting.
            </ActionItem>
            <ActionItem>Review event form for accuracy and submit.</ActionItem>
            <ActionItem>
              If transcript is ordered, please prioritize review and signature
              of COTT.
            </ActionItem>
            <Acknowledgement>Thank you!</Acknowledgement>
          </div>
        </li>
      </ol>
    </div>
  )
}

import { Stack } from '@mantine/core'

import {
  ActionContainer,
  ActionText,
  ActionTitle,
} from '@/components/Event/NewScript/components/common/ScriptTimeline'
import { useStatePolicies } from '@/components/Event/NewScript/hooks/useStatePolicy'
import { EVENT_TYPE_SHORT_LABEL } from '@/constants'
import { Event, ScriptAttendee } from '@/types'

type Props = {
  witness: ScriptAttendee
  event: Event
}

export const SwearIn = ({ witness, event }: Props) => {
  const { isCT } = useStatePolicies(event)

  return isCT ? (
    <ActionContainer outer>
      <Stack>
        <ActionText mono>
          Thank you. Mr./Ms. {witness.name}. Please raise your right hand. Do
          you solemnly swear that the statements you make here will be true to
          the best of your knowledge and belief upon the pains and penalties of
          perjury or false statement?
        </ActionText>
        <ActionContainer>
          <ActionTitle>Reminder</ActionTitle>
          <ActionText>
            If the witness objects to using the word “swear” for religious
            reasons or otherwise, please use the following oath script: <br />
            <br />
            <ActionText mono>
              Thank you. Mr./Ms. {witness.name}. Please raise your right hand.
              Do you solemnly and sincerely affirm and declare that the
              statements you make here will be true to the best of your
              knowledge and belief upon the pains and penalties of perjury or
              false statement?
            </ActionText>
          </ActionText>
        </ActionContainer>
      </Stack>
    </ActionContainer>
  ) : (
    <ActionContainer outer>
      <ActionText mono>
        Thank you. Mr./Ms. {witness.name}, Please raise your right hand. Do you
        swear or affirm to tell the truth, the whole truth and nothing but the
        truth during this {EVENT_TYPE_SHORT_LABEL[event.type]}?
      </ActionText>
    </ActionContainer>
  )
}

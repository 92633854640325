import React from 'react'
import { User } from '@auth0/auth0-react'

import { useScriptState } from '@/components/Event/NewScript/hooks/state/useScriptState'
import { useFormattedCurrentDateParts } from '@/components/Event/NewScript/hooks/useFormattedCurrentDateParts'
import { isExaminationUnderOath } from '@/components/Event/NewScript/utils/event'
import {
  AttendeeRole,
  EVENT_TYPE_LONG_LABEL,
  EVENT_TYPE_SHORT_LABEL,
  EventType,
  ParrotContact,
} from '@/constants'
import { Event } from '@/types'

type OpeningVerbatimProps = {
  event: Event
  user: User & { id: string; role: string }
  isNY: boolean
}

export const OpeningVerbatim: React.FC<OpeningVerbatimProps> = ({
  event,
  user,
  isNY,
}) => {
  const isEUO = isExaminationUnderOath(event)
  const dateTime = useFormattedCurrentDateParts(event.team.timezone)
  const caseNumber = event.case.number ?? '[ ]'
  const reporterCounty = user.county ?? '[ ]'
  const reporterState = user.state ?? '[ ]'
  const { state } = useScriptState(event)
  const witness = state.attendees.find(
    attendee => attendee.role === AttendeeRole.WITNESS,
  )

  if (isNY) {
    return (
      <>
        Good {dateTime.partOfTheDay}, we are now on the record. Today is{' '}
        {dateTime.day}. It is {dateTime.month} {dateTime.date}, {dateTime.year},
        the time is {dateTime.time} {dateTime.timezone}. We are present to
        record a remote {EVENT_TYPE_LONG_LABEL[event.type]} in the case of{' '}
        {event.case.name}, court case number {caseNumber} on behalf of{' '}
        {event.team.organization.name}. My name is {user.name}, I will be the
        Parrot Digital Reporter for today’s {EVENT_TYPE_SHORT_LABEL[event.type]}
        . I am located in {reporterState}, {reporterCounty} and I work with
        Parrot which is located at {ParrotContact.ADDRESS}.
      </>
    )
  }

  if (isEUO) {
    return (
      <>
        Good {dateTime.partOfTheDay}, we are now on the record. Today is{' '}
        {dateTime.day}. It is {dateTime.month} {dateTime.date}, {dateTime.year},
        the time is {dateTime.time} {dateTime.timezone}. We are present to
        record the Examination Under Oath of {witness?.name ?? '<WITNESS>'}. My
        name is {user.name}, I will be the Parrot Digital Reporter for today’s
        proceedings.
      </>
    )
  }

  return (
    <>
      Good {dateTime.partOfTheDay}, we are now on the record. Today is{' '}
      {dateTime.day}. It is {dateTime.month} {dateTime.date}, {dateTime.year},
      the time is {dateTime.time} {dateTime.timezone}. We are present to record{' '}
      {event.type === EventType.EXAMINATION_UNDER_OATH ? 'an' : 'a'}{' '}
      {EVENT_TYPE_LONG_LABEL[event.type]} in the case of {event.case.name},
      court case number {caseNumber}. My name is {user.name}, I will be the
      Parrot Digital Reporter for today’s {EVENT_TYPE_SHORT_LABEL[event.type]}.
    </>
  )
}
